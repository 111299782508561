// @flow
import * as React from 'react'
import { Hero, Link as UiLink } from '@toggl/ui'

import LoginForm from 'components/LoginForm'
import background from 'images/uploads/hero-laptops.jpg'

const chromeButtonUrl =
  'https://chrome.google.com/webstore/detail/toggl-button-productivity/oejgccbfbmkkpaidnkphaiaecficdnfn'
const firefoxButtonUrl =
  'https://addons.mozilla.org/en-US/firefox/addon/toggl-button-time-tracker/'

const onSuccess = () => {
  window.postMessage(
    {
      direction: 'from-public-web',
      message: 'login-success',
    },
    '*'
  )
}

const LoginPage = () => {
  const HeroComponent = ({ children }) => (
    <Hero.Wrapper bgImage={background}>
      {children}

      <Hero.LoginWrapper>
        <Hero.Title centered>
          <span>
            Get Toggl Button for{' '}
            <a href={chromeButtonUrl} target="_blank" rel="noreferrer">
              <UiLink.Underlined>Chrome</UiLink.Underlined>
            </a>{' '}
            or{' '}
            <a href={firefoxButtonUrl} target="_blank" rel="noreferrer">
              <UiLink.Underlined>Firefox</UiLink.Underlined>
            </a>
          </span>
        </Hero.Title>
        <Hero.LoginSmallTitle centered>
          {'Log in to Toggl Button'}
        </Hero.LoginSmallTitle>
      </Hero.LoginWrapper>
    </Hero.Wrapper>
  )

  return <LoginForm onSuccess={onSuccess} Hero={HeroComponent} />
}

export default LoginPage
